<template>
  <!--  审核拒绝弹出框  -->
  <el-dialog
    :title="platFormTitle"
    :visible.sync="dialogFormVisible"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents1">
      <el-form
        :model="platForm"
        :rules="rules"
        ref="platForm"
        label-width="auto"
      >
        <el-form-item label="集配业务中心" prop="logistic_business_id">
          <el-select
            v-show="platFormTitle === '新增手续费'"
            v-model="platForm.logistic_business_id"
            clearable
            placeholder="选择集配业务中心"
          >
            <el-option
              v-for="item in businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="
                tableData.some((val) => val.logistic_business_name == item.name)
                  ? true
                  : false
              "
            >
            </el-option>
          </el-select>
          <el-select
            v-show="platFormTitle === '编辑手续费'"
            v-model="platForm.logistic_business_id"
            :disabled="true"
            clearable
            placeholder="选择集配业务中心"
          >
            <el-option
              v-for="item in businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="平台服务费" prop="threshold_value">
          <div class="d-flex a-center">
            <span style="white-space: nowrap">单价基点：</span>
            <el-input
              v-model="platForm.threshold_value"
              @blur="platBlur"
              placeholder="请输入单价基点"
              clearable
              ><span slot="suffix" style="color: #333333">元</span></el-input
            >
          </div>
        </el-form-item>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="first_symbol">
              <el-select
                @change="onChangeSymbol('first_symbol')"
                v-model="platForm.first_symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item of greaterList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-form-item label-width="0">
              <div style="text-align: center">基点</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="first_amt" label-width="0">
              <el-input
                v-model="platForm.first_amt"
                @blur="baseBlur('first_amt')"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix" style="color: #333333">元/件</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item prop="last_symbol">
              <el-select
                @change="onChangeSymbol('last_symbol')"
                v-model="platForm.last_symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item of lessList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-form-item label-width="0">
              <div style="text-align: center">基点</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="last_amt" label-width="0">
              <el-input
                v-model="platForm.last_amt"
                @blur="baseBlur('last_amt')"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix" style="color: #333333">元/件</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态">
          <el-radio-group v-model="platForm.state">
            <el-radio
              :label="item.value"
              v-for="item of stateList"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFrom">确认</el-button>
    </span>
  </el-dialog>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import tool from "@/utils/tools/tool";
import { Loading } from "element-ui";
import { postFeeSave, getFeeEdit } from "@/api/generalControl/fund-config";
import { GREATER_ENUM, LESS_ENUM, STATE_ENUM } from "../utils/enum";
export default {
  props: ["tableData", "businessList"],
  name: "fundConfigDialog",
  data() {
    return {
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制手续费配置数据，用于初始化
      greaterList: Object.values(GREATER_ENUM), //大于列表
      lessList: Object.values(LESS_ENUM), //小于列表
      stateList: Object.values(STATE_ENUM), //状态列表
      platForm: {
        logistic_business_id: "",
        state: 2,
        threshold_value: "",
        first_symbol: "",
        first_amt: "",
        last_symbol: "",
        last_amt: "",
      },
      rules: {
        logistic_business_id: [
          {
            required: true,
            message: "请选择集配中心",
            trigger: ["blur", "change"],
          },
        ],
        threshold_value: [
          {
            required: true,
            message: "请输入单价基点",
            trigger: ["blur", "change"],
          },
        ],
        first_symbol: [
          {
            required: true,
            message: "请选择比较符",
            trigger: ["blur", "change"],
          },
        ],
        first_amt: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["blur", "change"],
          },
        ],
        last_symbol: [
          {
            required: true,
            message: "请选择比较符",
            trigger: ["blur", "change"],
          },
        ],
        last_amt: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     *新增数据
     */
    addData() {
      this.platFormTitle = "新增手续费";
      this.platForm = {
        ...this.platFormClone,
      };
      this.initData();
    },
    /**
     *编辑数据
     */
    async editData(val) {
      console.log(val);
      this.platFormTitle = "编辑手续费";
      let load = Loading.service({ fullscreen: true });
      try {
        let params = {
          logistic_business_id: val.logistic_business_id,
        };
        let { data } = await getFeeEdit(params);
        for (let key in this.platForm) {
          this.platForm[key] = data[key];
        }
        this.platForm.threshold_value = +tool.filterUnNumber(
          this.platForm.threshold_value
        );
      } catch (err) {
        console.error("ajax getFeeEdit err", err);
      } finally {
        load.close();
        this.initData();
      }
    },
    /**
     * 获得集配中心列表
     */
    async ajaxGetBusinessList() {
      try {
        const { data } = await this.$api.general.ajaxGetLogisticsForRole();
        this.businessList = data;
      } catch (err) {
        console.error("ajax ajaxGetBusinessList err", err);
      }
    },
    /**
     * 校验平台服务费
     */
    platBlur() {
      this.platForm.threshold_value = +tool.filterUnNumber(
        this.platForm.threshold_value
      );
    },
    /**
     * 校验基数
     */
    baseBlur(val) {
      this.platForm[val] = +tool.filterUnNumber(this.platForm[val], true);
    },
    /**
     * 修改平台服务费基点
     */
    onChangeSymbol(val) {
      // 重置数据
      if (val == "first_symbol") {
        this.platForm.last_symbol = "";
      } else {
        this.platForm.first_symbol = "";
      }
      // 修改运算
      switch (this.platForm.first_symbol) {
        case 1:
          this.platForm.last_symbol = 4;
          break;
        case 2:
          this.platForm.last_symbol = 3;
          break;
      }
      switch (this.platForm.last_symbol) {
        case 3:
          this.platForm.first_symbol = 2;
          break;
        case 4:
          this.platForm.first_symbol = 1;
          break;
      }
    },
    /**
     * 新增手续费配置
     */
    onHandleAdd() {
      this.platForm = {
        ...this.platFormClone,
      };
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     * 编辑提交表单
     */
    submitFrom: debounce(function () {
      this.$refs.platForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$confirm(`是否确认${this.platFormTitle}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(async () => {
          // let params = {
          //   after_id: this.as_id,
          //   remark: this.refuseReason,
          // };
          console.log(this.platForm);
          try {
            await postFeeSave(this.platForm);
            this.$emit("submit-form");
            this.tool.message("成功" + this.platFormTitle, "success");
          } catch (error) {
            console.error("postForceIntervene", error);
          } finally {
            this.dialogFormVisible = false;
          }
        });
      });
    }, 300),
  },
};
</script>
