<template>
  <div class="fundconfig-wrapper bj">
    <div class="title">
      <div class="seach order-wrapper">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="onHandleRefresh"
        ></el-button>
        <el-button
          @click="onHandleFund('addData')"
          :disabled="tableData.length == businessList.length"
          type="primary"
          icon="el-icon-search"
          >新增</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="80" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span>{{ scope.row?.logistic_business_name || "——" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          min-width="150"
          show-overflow-tooltip
          align="center"
          label="手续费配置"
        >
          <template slot-scope="scope">
            <span class="mr10">收费基点：{{ scope.row.threshold_value }}</span>
            <span class="mr30">{{
              `${scope.row.first_symbol_txt}${scope.row.threshold_value}元，每件${scope.row.first_amt}元`
            }}</span>
            <span>{{
              `${scope.row.last_symbol_txt}${scope.row.threshold_value}元，每件${scope.row.last_amt}元`
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                stateList.find((item) => {
                  return item.value === (scope.row.state || 2);
                }).label == '启用'
                  ? 'success'
                  : 'warning'
              "
              >{{
                stateList.find((item) => {
                  return item.value === (scope.row.state || 2);
                }).label
              }}</el-tag
            >
            <span></span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center" fixed="right">
          <template slot-scope="scope">
            <div
              @click="onHandleFund('editData', scope.row)"
              class="d-flex main-color pointer text-none"
              style="justify-content: space-around"
            >
              编辑
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 手续费配置 -->
    <fund-config-dialog
      ref="fundCostConfig"
      :tableData="tableData"
      :businessList="businessList"
      @submit-form="submitForm"
    ></fund-config-dialog>
  </div>
</template>
<script>
import { getFeeList } from "@/api/generalControl/fund-config";
import fundConfigDialog from "./modules/index.vue";
import { GREATER_ENUM, LESS_ENUM, STATE_ENUM } from "./utils/enum";
export default {
  name: "UnPlatformParticipation",
  components: { fundConfigDialog },
  data() {
    return {
      loading: false,
      greaterList: Object.values(GREATER_ENUM), //大于列表
      lessList: Object.values(LESS_ENUM), //小于列表
      stateList: Object.values(STATE_ENUM), //状态列表
      tableData: [], //列表数据
      businessList: [], //集配中心数据
    };
  },
  created() {
    this.initData();
  },
  methods: {
    /**
     * 初始化数据
     */
    initData() {
      this.ajaxGetFeeList();
      this.ajaxGetBusinessList();
    },
    /**
     * 获得集配中心列表
     */
    async ajaxGetBusinessList() {
      try {
        const { data } = await this.$api.general.ajaxGetLogisticsForRole();
        this.businessList = data;
      } catch (err) {
        console.error("ajax ajaxGetBusinessList err", err);
      }
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.ajaxGetFeeList();
    },
    /**
     * 手续费配置
     */
    onHandleFund(func, val) {
      this.$refs.fundCostConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.ajaxGetFeeList();
    },
    /**
     * 获得列表数据
     */
    async ajaxGetFeeList() {
      this.loading = true;
      try {
        const { data } = await getFeeList();
        this.tableData = data;
        console.log(data);
      } catch (err) {
        console.error("ajax ajaxGetFeeList err", err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.fundconfig-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .contents1 {
    box-sizing: border-box;
    padding: 10px 26px 40px;
    .base-margin {
      margin: 0 10px;
      white-space: nowrap;
    }
  }
  .mr30 {
    margin-right: 30px;
  }
  .order-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 20px;
    .inputs {
      display: flex;
      .label {
        line-height: 38px;
      }
    }
  }
  .title {
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
