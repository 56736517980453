import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-资金配置-资金配置列表
 * @param {*} params
 * @returns
 */
export function getFeeList(params = {}) {
  return axios.get(`${BASE_URL}/platform/fee/list`, {params});
}

/**
 * 总控-资产配置-资金配置编辑查询
 * @param {*} params
 * @returns
 */
export function getFeeEdit(params = {}) {
  return axios.get(`${BASE_URL}/platform/fee/edit`, {params});
}

/**
 * 总控-资金配置-保存资金配置
 * @param {*} params
 * @returns
 */
export function postFeeSave(params = {}) {
  return axios.post(`${BASE_URL}/platform/fee/save`, params);
}
