/** 大于枚举 */
export const GREATER_ENUM = {
  greater: {
    value: 1,
    label: '大于'
  },
  greater_equal: {
    value: 2,
    label: '大于等于'
    },
}

/** 小于枚举 */
export const LESS_ENUM = {
  less: {
    value: 3,
    label: '小于'
  },
  less_equal: {
    value: 4,
    label: '小于等于'
  },
}

/** 状态枚举 */
export const STATE_ENUM = {
  unDisabled: {
    value: 1,
    label: '启用'
  },
  disabled: {
    value: 2,
    label: '禁用'
  }
}